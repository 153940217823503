import { createGlobalStyle } from 'styled-components';
import fontBlack from '../assets/fonts/Heebo-Black.ttf';
import fontMedium from '../assets/fonts/Heebo-Medium.ttf';
import modak from '../assets/fonts/Modak-Regular.ttf';


require("typeface-heebo")

const Typography = createGlobalStyle`
    /* @font-face {
        font-family: Heebo;
        src: url(${fontBlack});
        font-weight: 900;
        font-style: normal;
    }
    @font-face {
        font-family: Heebo;
        src: url(${fontMedium});
        font-weight: 500;
        font-style: normal;
    } */
    @font-face {
        font-family: Modak;
        src: url(${modak});
        font-weight: normal;
        font-style: normal;
    }
    
`

export default Typography;
