import React from 'react'

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'



// function EmptyState(props) {
//   // console.log('empty props', props)
//   if (props.filteredGalleries.length == 0) {
//     const filters = props.filterStrings.join(',')
//     return <div>No galleries matching {filters}</div>
//   }
//   else {
//     return <></>
//   }
// }

const PasswordStyles = styled.section`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 50000; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .heading {
        font-size: 32px;
    }
    form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 24px;
        button, .button, input[type="submit"] {
            border: none;
            /* background: #635bff; */
            background: #fff;
            padding: 6px;
            width: 100%;
            /* color: #f3f2ff; */
            border: 1px solid #000;
            border-radius: 6px;
            cursor: pointer;
            transition: .3s all ease-in-out;
            &:hover {
                border-color: #5f5f5f;
                background: #5f5f5f;
                color: #fff;
            }
        }
    }
    .error {
        font-size: 10px;
        color: red;
        margin: 16px 0;
    }
  `


class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        showPassword: true,
        showError: false,
        showForm: false,
        password: 'ImogenTest2021',
        inputValue: ''
    };
  }

  componentDidMount() {
      document.body.style.overflow = 'hidden';
    //   document.getElementById('gatsby-focus-wrapper').getElementsByTagName('div').style.opacity = 0;
  }

  revealForm = () => {
      this.setState({ showForm: true })
  }

  handleChange = (e) => {
      this.setState({ inputValue: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.inputValue == this.state.password) {
        this.setState({ showPassword: false });
        document.body.style.overflow = 'visible';
        document.getElementById('gatsby-focus-wrapper').classList.add('password-free');
    }
    else {
        this.setState({ showError: true })
    }
  }

 

  render() {


    return (
        <>
            {
                this.state.showPassword &&
                    <PasswordStyles>
                        <div className="heading" onClick={this.revealForm}>COMING SOON</div>
                        {
                            this.state.showForm &&
                                <form>
                                    <input type="password" placeholder="Password" value={this.state.inputValue} onChange={this.handleChange}></input>
                                    <button onClick={this.handleSubmit}>SUBMIT</button>
                                </form>
                        }
                        
                        {
                            this.state.showError &&
                            <span className="error">Incorrect Password</span>
                        }
                    </PasswordStyles>
            }
        </>
    )
  }
}
export default Password
