import { createGlobalStyle } from 'styled-components';
import { device } from './Breakpoints'

import variables from './variables.css'
// ^^ not explicitly used here but somehow used throughout elsewhere so do not delete


const GlobalStyles = createGlobalStyle`

    * {
        box-sizing: border-box;
        cursor: url('https://cur.cursors-4u.net/anime/ani-1/ani180.cur'), auto;
        
    }
    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        &:visited {
            color: inherit;
        }
    }

    body, html {
        width: 100vw;
        max-width: 100vw;
    }

    html {
        font-family: var(--body-font);
        font-weight: 500;
        color: var(--black);
    }

    img {
        max-width: 100%;
    }

    .d-flex {
        display: flex;
    }
    main {
        position: relative;
        background-color: #fff;
    }

    .container {
        max-width: 1440px;
        padding-left: 72px;
        padding-right: 72px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        @media ${device.$medium} {
            max-width: 100vw;
            padding-left: 12px;
            padding-right: 12px;
        }
        @media ${device.$xlarge} {
            max-width: 1920px;
        }
    }

    .masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        justify-content: center;
        margin-left: -24px; /* gutter size offset */
        width: auto;
    }
    .masonry-grid__column {
        padding-left: 24px; /* gutter size */
        background-clip: padding-box;
    }
    
    /* Style your items */
    .masonry-grid__column > div { 
        margin-bottom: 24px;
    }

    .visually-hidden {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }

    form {
        input {
            border: 0;
            border-bottom: 1px solid #000;
            background-color: transparent;  
            -webkit-border-radius:0; 
            margin: 12px 0;
            padding: 0 0 4px 4px;
            &:focus {
                outline: none;
            }
        }
    }
    ::placeholder {
        font-size: 12px;
        color: #dadada;
    }

    button {
  
        &:focus {
            outline: none;
        }
    }

    // Remove when password removed::::
    #gatsby-focus-wrapper > div,
    #gatsby-focus-wrapper > main,
    #gatsby-focus-wrapper > footer {
        opacity: 0;
    }
    #gatsby-focus-wrapper.password-free > div,
    #gatsby-focus-wrapper.password-free > main,
    #gatsby-focus-wrapper.password-free > footer {
        opacity: 1;
    }
`

export default GlobalStyles;

