import React from 'react'
import GlobalStyles from '../styles/GlobalStyles';
import 'normalize.css'


import Typography from '../styles/Typography';
import SEO from './SEO';
import Password from './Password';


export default function Layout(props) {
    console.group("Site Credits");
    console.log('Designed & Developed by Matt Crowley');
    console.log('mshcrowley@gmail.com');
    console.groupEnd()
    return (
        <>
            <SEO />
            <GlobalStyles />
            <Typography />
            <Password />
            {props.children}
        </>
    )
}